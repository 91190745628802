var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "step_pay" },
    [
      _c("RoleInfo"),
      _c("GoodsList", { on: { onSelect: _vm.handleSelectGoods } }),
      _c("PopOrderPay", {
        ref: "payCart",
        on: {
          close: function ($event) {
            _vm.dialogCart = false
          },
          onPay: (info) => _vm.popupWait(info),
        },
        model: {
          value: _vm.dialogCart,
          callback: function ($$v) {
            _vm.dialogCart = $$v
          },
          expression: "dialogCart",
        },
      }),
      _c("PopOrderCancel", {
        attrs: { visible: _vm.dialogOrder },
        on: {
          "update:visible": function ($event) {
            _vm.dialogOrder = $event
          },
          onFinish: _vm.closeLoading,
          onSuccess: function ($event) {
            _vm.dialogCart = true
          },
        },
      }),
      _c("Popup", {
        attrs: {
          title: _vm.popInfo.title,
          msg: _vm.popInfo.msg,
          btn: _vm.popInfo.btn,
          msgAlign: _vm.popInfo.align,
          tips: _vm.popInfo.tips,
          modelClose: false,
        },
        on: {
          init: _vm.popInfo.init,
          close: _vm.handleCloseWait,
          confirm: _vm.handleCloseWait,
        },
        model: {
          value: _vm.dialogTips,
          callback: function ($$v) {
            _vm.dialogTips = $$v
          },
          expression: "dialogTips",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }