<template>
    <div class="step_pay">
        <RoleInfo />

        <GoodsList @onSelect="handleSelectGoods" />

        <PopOrderPay
            ref="payCart"
            v-model="dialogCart" 
            @close="dialogCart = false" 
            @onPay="info => popupWait(info)" 
        />

        <PopOrderCancel 
            :visible.sync='dialogOrder' 
            @onFinish="closeLoading"
            @onSuccess="dialogCart = true"
        />

        <Popup 
            v-model="dialogTips" 
            :title="popInfo.title" 
            :msg="popInfo.msg" 
            :btn="popInfo.btn"
            :msgAlign="popInfo.align" 
            :tips="popInfo.tips" 
            @init="popInfo.init" 
            @close="handleCloseWait"
            :modelClose="false" 
            @confirm="handleCloseWait" 
        />

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { formatTime } from '@/utils'
import { getPayResult, removePayResult, getPayInfo, removePayInfo } from '@/utils/storage'
import { apiOrderResult } from '@/api/mall'
import { logPurchaseSuccess, logPurchaseFail } from '@/utils/log'
import { Loading } from 'element-ui'
import Popup from '@/components/Popup'
import GoodsList from './GoodsList'
import RoleInfo from '../RoleInfo'
import PopOrderPay from '@/components/Pop/PopOrderPay'
import PopOrderCancel from '@/components/Pop/PopOrderCancel'

const popInfoDefault = {
    title: '',
    msg: '',
    btn: null,
    tips: null,
    init: () => { }
}

export default {
    name: 'StepPay',
    components: {
        RoleInfo,
        GoodsList,
        Popup,
        PopOrderPay,
        PopOrderCancel
    },
    data() {
        return {
            dialogTips: false,      // 开关，提示弹窗（成功、失败、倒计时）
            dialogCart: false,      // 开关，结算弹窗
            dialogOrder: false,     // 开关，取消订单弹窗
            popInfo: Object.assign({}, popInfoDefault),
            timerTime: undefined,
            remainingTime: 0,
            payInfo: null,
            loadingFull: null
        }
    },
    computed: {
        ...mapGetters(['isLogin'])
    },
    methods: {
        ...mapActions('mall', ['getGoodsList', 'selectGoods']),
        ...mapActions('user', ['login']),
        ...mapActions('game', ['getMaintainInfo']),
        popupWait({ time, order, isPop }) {
            this.dialogCart = false

            if (isPop) {
                this.popup({
                    btn: this.$sys('btn.confirm'),
                    align: 'center',
                    tips: this.$sys('warn.paying'),
                    init: () => {
                        this.timerTime = setInterval(() => {
                            const payResult = getPayResult()
                            if (payResult == 0) {
                                this.handleCloseWait()
                            }
                            if (payResult == 1) {
                                this.handleCloseWait()
                                setTimeout(() => {
                                    this.popup({
                                        title: this.$sys('mall.buySuccess'),
                                        msg: this.$sys('mall.buySuccessMsg'),
                                        btn: this.$sys('mall.buyContinue')
                                    })
                                }, 1000)
                            }
                            if (time % 20 === 0) {
                                time = this.remainingTime
                            }
                            if (time >= 0) {
                                this.popInfo.msg = `${this.$sys('warn.payWaiting')}<br/><span style="color:red">${formatTime(time)}</span>`
                                time--
                            } else {
                                this.handleCloseWait()
                            }
                        }, 1000)
                    }
                })

                this.queryPayResult(order)
            }


            this.getGoodsList()
        },
        handleCloseWait() {
            removePayResult()
            this.$refs.payCart && this.$refs.payCart.reset()
            this.dialogTips = false
            this.timerTime && clearInterval(this.timerTime)
            this.selectGoods()

            this.getGoodsList()     // 后加，为了刷新商品角标
        },
        async queryPayResult(orderCode) {

            const result = await apiOrderResult(orderCode)

            if (result.code === 0) {
                this.remainingTime = result.data.timeRemain
                
                this.payInfo = this.payInfo || getPayInfo()

                if (result.data.paySuccess) {
                    this.handleCloseWait()

                    this.popup({
                        title: this.$sys('mall.buySuccess'),
                        msg: this.$sys('mall.buySuccessMsg'),
                        btn: this.$sys('mall.buyContinue')
                    })

                    if(this.payInfo) {
                        logPurchaseSuccess(this.payInfo)
                        removePayInfo()
                    }
                } else if (result.data.paySuccess === false){
                    this.handleCloseWait()

                    this.popup({
                        msg: this.$sys('mall.status.payFail'),
                        btn: this.$sys('btn.confirm')
                    })

                    if(this.payInfo) {
                        logPurchaseFail('other')
                        removePayInfo()
                    }
                } else {
                    if (this.dialogTips) {
                        setTimeout(() => {
                            this.queryPayResult(orderCode)
                        }, 1000)
                    }
                }
            }
        },
        popup(info) {
            this.dialogTips = false
            this.popInfo = Object.assign({}, popInfoDefault, info)
            this.dialogTips = true
        },
        async handleSelectGoods(info) {
            // 接口查询：查维护状态 -> 查订单 -> 查商品列表（非必须） 
            if (!this.isLogin) {
                this.login(info.commodityCode)
                return
            }

            if(this.timerLoading) return

            this.timerLoading = setTimeout(() => {
                this.loadingFull = Loading.service({ 
                    fullscreen: true, 
                    spinner: 'el-icon-loading', 
                    background: 'rgba(0, 0, 0, 0.7)', 
                    lock: true 
                })
            }, 500)

            try {
                const isMaintain = await this.getMaintainInfo()
                if(isMaintain) {
                    this.closeLoading()
                }
            }catch(e) {
                this.loadingFull?.close()
            }

            
            this.dialogOrder = true
            this.selectGoods(info)
        },
        closeLoading() {
            this.timerLoading && clearTimeout(this.timerLoading)
            this.loadingFull?.close()
            this.timerLoading = null
        }
    }
}
</script>

<style lang="scss" scoped>
.step_pay {
    background-color: #fff;
}
</style>