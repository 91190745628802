<template>
  <div class='goods_section'>

    <Title :name="$sys('mall.goodsTitle')"></Title>

    <DynamicCopywriting />

    <ul 
      v-if='goodsList.length > 0' 
      v-loading="loading"
      class='goods_list flex-start' 
    >
      <li v-for='(item, index) in goodsList' :key='index'>
        <a
          href='javascript:;'
          class='item p-r flex-column-center clickable'
          :class="{'active': goodsInfo.commodityCode === item.commodityCode}"
          @click="$emit('onSelect', item)"
        >
          <span v-if='item.cornerMarkText' class='corner-mark' :title="item.cornerMarkText">{{ item.cornerMarkText }}</span>

          <span v-if='item.isRecommend' class='hot'></span>

          <img :src='item.commodityIcon' class='img' alt=''>

          <div class='goods-wrap'>
            
            <p class='name ellipse' :title='item.commodityName'>{{ item.commodityName }}</p>

            <p class='descr ellipse' :title="item.preferentialExplain">{{ item.preferentialExplain }}</p>

            <p class='prize' :title='toCurrencyThousand(item.commoditySellPrice)'>

              <template v-if='item.currencyFrontLocation == 11'>
                <a> {{ toCurrencyThousand(item.commoditySellPrice) }} {{ item.commodityCurrency }}</a>
              </template>
              
              <template v-else>
                {{ item.commodityCurrency }} {{ toCurrencyThousand(item.commoditySellPrice) }}
              </template>

            </p>

          </div>

        </a>
      </li>
    </ul>

    <span id='helper'></span>

    <div :class='["instructions"]' v-html="$xy('recharge.instructions')"></div>

  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { formatDateTime, formatCurrencyToThousand } from '@/utils'
import Title from '../Title'
import DynamicCopywriting from './DynamicCopywriting'


export default {
  data() {
    return {
      instructions: '',
      loading: true
    }
  },
  components: {
    Title,
    DynamicCopywriting
  },
  computed: {
    ...mapGetters(['isMaintain', 'country', 'lang', 'isLogin', 'isMobile']),
    ...mapState('user', ['userInfo']),
    ...mapState('game', ['gameInfo']),
    ...mapState('mall', ['goodsList', 'goodsInfo', 'orderStatus']),
    status() {
      return [this.country, this.lang, this.userInfo, this.orderStatus]
    }
  },
  methods: {
    formatDateTime,
    ...mapActions('mall', ['getGoodsList', 'selectGoods']),
    toCurrencyThousand(value) {
      return formatCurrencyToThousand(value)
    }
  },
  watch: {
    status: {
      async handler() {
        this.loading = true
        if(!this.isMaintain) {
          await this.getGoodsList()
        }
        this.loading = false
      },
      immediate: true
    }
  }
}
</script>

<style lang='scss' scoped>

.f12 {
  font-size: 12px;
}
</style>
<style lang='scss'>
.instructions {
  margin-top: 100px;
  line-height: 30px;
  box-sizing: border-box;
  padding: 10px 0;
  font-size: 14px;
  color: #999999;
  font-weight: normal;
  p {
    min-height: 20px;
  }

  p:first-child {
    font-size: 16px;
    color: #333333;
  }

  p:nth-child(6) {
    font-size: 16px;
    color: #333333;
  }
  

}

@media only screen and (max-width: 750px) {
  .instructions {
    font-size: 14px;
    line-height: 31px;
    margin-top: 50px;

    p:first-child {
      font-size: 16px;
      margin-bottom: 5px
    }

    p:nth-child(6) {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}

.el-loading-mask {
  z-index: 1000;
}

</style>